import { ANALYTICS } from 'constants/analytics'
import Cookies from 'js-cookie'

/**
 * This function reverts the use of the HUB Profile ID used in a cookie for analytics.
 * This has been replaced with the combination of session_id and device_id
 */
const removeProfileCookie = () => {
  const profileId = Cookies.get(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME)
  if (!profileId) return
  Cookies.remove(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME)
}

export { removeProfileCookie }
