import { createContext, useReducer } from 'react'

const DtiContext = createContext()

const DTIReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_DTI_FORM':
      return {
        ...state,
        dataDti: action.payload.dataDti,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const DTIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DTIReducer, {
    dataDti: undefined,
  })
  return (
    <DtiContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DtiContext.Provider>
  )
}

export { DTIProvider, DtiContext }
