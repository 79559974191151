import { useContext } from 'react'
import { FeatureFlagContext } from 'providers/FeatureFlagProvider'

const useFeatureFlag = (flagName) => {
  const { featureFlags, featurePathOverrides = {} } = useContext(FeatureFlagContext)

  // featurePathOverrides stores flags set specifically as an override for a specific route which
  // is added when generating page props. Use this value if an override is present for this route.
  if (Object.hasOwn(featurePathOverrides, flagName)) {
    return featurePathOverrides[flagName]
  }

  return featureFlags?.[flagName]
}

export { useFeatureFlag }
