import { useTrackPage, useSessionId, removeProfileCookie } from 'utils/analytics'
import { Tealium } from 'components/Tealium'
import { useDatadog } from 'utils/datadog'
import { useBot } from 'hooks/useBot'

function Analytics({ children, pageVariation, pageSectionsVariations, loadEventProps }) {
  useSessionId()

  removeProfileCookie()

  useDatadog()

  useTrackPage(pageVariation, pageSectionsVariations, loadEventProps)

  const { isBot } = useBot()

  return (
    <>
      {!isBot ? <Tealium /> : null}
      {children}
    </>
  )
}

export { Analytics }
