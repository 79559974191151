import { useEffect, useContext } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { AnalyticsContext, useConfig } from 'providers/AnalyticsProvider'
import { ANALYTICS } from 'constants/analytics'
import { useBot } from 'hooks/useBot'

const initDatadog = ({ config }) => {
  // Set defaults from ENV
  const applicationId = config.NEXT_PUBLIC_DATADOG_APPLICATION_ID
  const clientToken = config.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
  const env = config.NEXT_PUBLIC_DATADOG_ENV
  const service = config.NEXT_PUBLIC_DATADOG_SERVICE
  // Specify a version number to identify the deployed version of your application in Datadog
  // We will get this from build number pipeline, since it's inlined at build, we can access it via process.env
  const version = process.env.NEXT_PUBLIC_VERSION ? process.env.NEXT_PUBLIC_VERSION : '0.0.0'

  if (!applicationId || !clientToken || !env) return
  datadogRum.init({
    applicationId,
    clientToken,
    site: ANALYTICS.DATADOG_SITE,
    service,
    env,
    version,
    sampleRate: 100,
    premiumSampleRate: 50,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const useDatadogUser = () => {
  const {
    state: {
      browser_id: achieve_browser_id,
      session_id: achieve_session_id,
      profile_id: achieve_profile_id,
      auth0_user_id,
      auth0_email,
    } = {},
  } = useContext(AnalyticsContext)

  useEffect(() => {
    datadogRum.setUser({
      achieve_browser_id,
      achieve_session_id,
      achieve_profile_id,
      auth0_user_id,
      auth0_email,
    })
    return () => {
      datadogRum.removeUser()
    }
  }, [achieve_browser_id, achieve_session_id, achieve_profile_id, auth0_user_id, auth0_email])
}

const useDatadog = () => {
  const { config } = useConfig()
  const { isBot } = useBot()
  useEffect(() => {
    if (isBot) return
    initDatadog({ config })
    datadogRum.startSessionReplayRecording()

    return () => {
      datadogRum.stopSessionReplayRecording()
    }
  }, [config, isBot])

  useDatadogUser()
}

export { useDatadog }
