import { createContext } from 'react'

const FeatureFlagContext = createContext({})

const FeatureFlagProvider = ({ children, featurePathOverrides, featureToggleObject }) => {
  return (
    <FeatureFlagContext.Provider
      value={{ featureFlags: featureToggleObject, featurePathOverrides }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

export { FeatureFlagProvider, FeatureFlagContext }
