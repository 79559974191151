import { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'

/**
 * A custom hook that checks if a specific string is present in a query parameter that can or not have multiple values separated by commas.
 *
 * @param {string} queryParamKey - The key of the query parameter to check.
 * @param {string} checkString - The string to check for in the query parameter.
 * @returns {boolean} Whether the checkString is present in the query parameter.
 */
const useSearchParamChecker = (queryParamKey, checkString) => {
  const searchParams = useSearchParams()
  const [isStringPresent, setIsStringPresent] = useState(null)

  useEffect(() => {
    // Helper function to parse and clean the search parameters
    const parseAndClean = () => {
      if (!searchParams) return []
      const queryValue = searchParams.get(queryParamKey)
      if (!queryValue) return []

      // If the query parameter is present, split its value by commas,
      // match only the word characters at the beginning of each part,
      // and filter out any null or empty strings
      return queryValue
        .split(',')
        .map((part) => {
          const match = part.match(/^\w+/)
          return match ? match[0] : null
        })
        .filter(Boolean)
    }
    // Helper function to check if a specific string is present in an array
    const checkStringInArray = (array, stringToCheck) => {
      return array.includes(stringToCheck)
    }

    // Parse and clean the search parameters
    const cleanedParams = parseAndClean()

    // Check if the specific string is present in the array of cleaned parameters
    setIsStringPresent(checkStringInArray(cleanedParams, checkString))
  }, [searchParams, checkString, queryParamKey])

  return isStringPresent
}

export default useSearchParamChecker
