import { useEffect, useContext } from 'react'
import { ANALYTICS } from 'constants/analytics'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'

/**
 * Session is only available on the client but some apps will need to access the session id from the
 * server. This checks to see if the session id is saved as a cookie and updates it if it doesn't
 * match.
 * @param {string} sessionId
 */
const syncSessionIdAsCookie = (sessionId) => {
  const sessionIdCookie = Cookies.get(ANALYTICS.SESSION_ID_KEY_NAME)
  if (sessionIdCookie === sessionId) return
  Cookies.set(ANALYTICS.SESSION_ID_KEY_NAME, sessionId)
}

const setSessionId = ({ dispatch }) => {
  // Session Storage persists until the tab/browser is closed.
  // Unlike session cookies which have been broken by browsers like Chrome.
  const sessionId = sessionStorage.getItem(ANALYTICS.SESSION_ID_KEY_NAME)

  const sessionTimerStart = sessionStorage.getItem(ANALYTICS.SESSION_TIMER_START)
  const currentTime = new Date().getTime()
  sessionStorage.setItem(ANALYTICS.SESSION_TIMER_START, currentTime)

  // Trigger a sync with cookies if we have a sessionId
  sessionId && syncSessionIdAsCookie(sessionId)

  if (sessionId && currentTime - sessionTimerStart < ANALYTICS.SESSION_TIMEOUT) return

  const newSessionId = uuidv4()

  sessionStorage.setItem(ANALYTICS.SESSION_ID_KEY_NAME, newSessionId)
  syncSessionIdAsCookie(newSessionId)

  dispatch({
    type: 'SET_SESSION_ID',
    id: newSessionId,
  })
}

const useSessionId = () => {
  const { dispatch } = useContext(AnalyticsContext)
  return useEffect(() => {
    setSessionId({ dispatch })
  }, [dispatch])
}

export { setSessionId, useSessionId }
